.mileage .container{
  max-width: 96%;
}
form {
  padding: 75px 0;
}

form .field-group {
  display: flex;
  margin-bottom: 30px;
  justify-content: flex-start;
  gap: 30px;
}
.mileage .field-group.second-section {
  position: relative;
}
.mileage .error-section {
  position: absolute;
  top: 72px;
  left: 45.3%;
  @media (max-width: 1600px) {
    left: 48%;
    top: 75px;
  left: 67.4%;
  }
}
.dash-icon{
  display: block;
}
.toast-msg{
  justify-content: right;
  margin-top: 20px;
  position: fixed;
  right: 38px;
  top: 40px;
  justify-content: center;
    margin-top: 20px;
    /* position: fixed; */
    right: 38px;
    top: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.toast-msg .alert-success{
padding: 6px 25px 10px;
}
.dash-icon{
  display: flex;
  justify-content:space-between;
  align-items: end;
  margin-top: 20px;
}
.delete-icon{
  margin-bottom: 40px;
}
.delete-icon img{
  height:34px;
  margin-top: 30px;
  cursor: pointer;
  
}
.mileage .field-group .error-msg{
  margin-top: -40px;
  font-size: 12px;
}
.add-icon{
  display: flex;
  justify-content:flex-start;
  margin-bottom: 20px;
  margin-left:11.3%;
}
.add-icon img{
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
}
}
.mileage h6.upload-receipt-title {
  margin-left: 71px;
}
a.dashboard-icon-upload img{
  height: 34px;
  margin-right: 60px;
}
form .field-group .label {
  flex: 1.5;
  text-transform: uppercase;
  font-size: 12px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  padding-top: 14px;
position: relative;
}
.mandatory-field {
  color: red!important;
  position: absolute;
  font-size: 12px;
  margin-left: 3px;
}
form .field-group .field {
  flex: 3;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.text-center{
  text-align: center;
}
.field-element {
  width: 32%;
}
.second-section .field-element{
  width: 23.5%;
  /* height: 70px; */
}
/*.form-column-layout{
  display: flex;
  flex-basis: 100%;
  flex: 1;
}*/
.mileage input{
  border: none;
  border-bottom: 1px solid #7c7e7f;
  outline: none;
  
  height: 37px;
  background: transparent;

}
input {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  padding: 2px 2px 2px 3px;
  border-radius: 0;
}
.info-icon {
  display: inline-block;
  position: absolute;
  top: 8px;
  width: 13px;
  margin-left: 10px;
}
.second-section label, .third-section label{
  margin-bottom: 0;
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .mileage input[type="date"] {
    width: 100%;
    border: none;
    color: #000;
    text-align: left;
    -webkit-appearance: none;
    border-bottom: 1px solid #7c7e7f;
    outline: none;
    background: transparent;
    padding-top: 13px!important;
    padding-right: 75%;
    position: relative;
    display: inline-block;
    border-radius: 0;
  }
}}
.mileage input[type="date"] {
  width: 100%;
  border: none;
  color: #000;
  text-align: left;
  -webkit-appearance: none;
  border-bottom: 1px solid #7c7e7f;
  outline: none;
  background: transparent;
  padding-top: 17px;
  /*padding-right: 32%;*/
  position: relative;
  display: inline-block;
  border-radius: 0;
}
input#mileNumber{
  border: none;
}
input#input-example-amount{
  border: none!important;
}
.upload-button.reimbursement:hover {
  color: #fff;
  background-color: #ff8a24;
  border-color: #ff8a24;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.field .css-1wa3eu0-placeholder {
  top: 70%;
  color: #000;
  margin: 0;
}

.field .css-1okebmr-indicatorSeparator {
  display: none;
}

.field-group .field .form-custom-class-prefix__control {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #7c7e7f;
  cursor: pointer;
  border-radius: 0;
  height: 30px;
}
.cc-class .form-custom-class-prefix__value-container , .cc-class .form-custom-class-prefix__indicators{
  position: relative;
    top: 7px;
}
.field-group .field .form-custom-class-prefix__menu {
  cursor: pointer !important;
}

.field-group .field .form-custom-class-prefix__menu__option--is-focused {
  cursor: pointer !important;
}

.field-group .field .form-custom-class-prefix__input-container {
  padding-top: 0px;
}

.form-custom-class-prefix__input-container {
  padding-bottom: 0;
}

.field-group .field .form-custom-class-prefix__indicator {
  color: #000;
  padding: 0;
}

.form-custom-class-prefix__indicator .form-custom-class-prefix__clear-indicator {
  padding: 0;
}

.field-group .field .form-custom-class-prefix__placeholder {
  color: #000;
}

.field-group .field .form-custom-class-prefix__value-container {
  padding: 3px 1px;
}

.field-group .field .form-custom-class-prefix__indicator svg {
  height: 15px;
}

input[type="file"] {
  border: none;
  display: block!important;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 14px;
  color: #fff;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.upload__image-wrapper {
  display: inline-flex;
  /*justify-content: center;*/
  align-items: center;
}
.button-message {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  width: 100%;
  max-width: 20%;
  margin: auto;
}
.button-wrap {
 position: relative;

}
.alert.alert-success.mt-3.mb-0{
  background: transparent;
  /* color: #4ebd4e; */
  text-align: center;
  border: 0;
  padding: 0;
  position: relative;
  top: -30px;
  left: 57px;
}
.alert.alert-success.green.mt-3.mb-0{
  color: #4ebd4e; 
}
.flex{
  display: flex;
}
.flex .credit{
  text-align: right;
  margin-right: 5px;
}
.flex label{
  position: relative;
  left: 5px;
  cursor: pointer;
}

.flex input{
  cursor: pointer;
}
@-moz-document url-prefix() {
  .mileage .upload-images-div {
    margin-left: 105px!important;
  }
}
.mileage .upload-images-div {
  margin-left: 75px;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  /* align-items: stretch; */
  /* width: 11%;
  margin: 0 auto; */
}
.upload-button.reimbursement {
  width: 200px;
  display: inline-block;
  padding: 14px 17px;
  cursor: pointer;
  background-color: #f3994c;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  border: none;
  text-transform: uppercase;
  text-align: center;
}
.mileage .container .receipt-save{
  width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mileage .spinner-border{
  left: 49%;
    top: 50%;
    position: absolute;
    color: #007bff;
}
.image-item__btn-wrapper button {
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 26px;
  left: 26px;

}

.image-item__btn-wrapper button:hover {
  background-color: transparent;
  color: red;
  border: none;
  position: absolute;
  bottom: 26px;
  left: 26px;
}

.image-item {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  height: max-content;

  border: 1px solid #ccc;

}

.image-item div {
  width: 45px;
  position: relative;
}

.image-item div img {
  object-fit: contain;
  display: inline-block;
  width: 100%;
  height: 45px;
  padding: 9px;

}

.image-item:hover {
  border: 1px solid red;
}

.logireceiptsn-form .receipt-check {
  position: absolute;
  bottom: 76px;
  margin-left: 50px;
}

.desk-view .label {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.mobile-view {
  display: none;
}

.desk-view {
  display: block;
}

/*.receive-save-wrapper {
  position: absolute;
  bottom: 38%;
  left: 46.6%;
}*/
.file-format{
  font-size: 11px;
    position: relative;
    top: -25px;
}
.receipts-form .sign-btn {
  display: flex;
  align-items: baseline;
  gap: 26px;
  position: relative;
  flex-direction: column;
}

input.receipt-check {
  position: relative;
    top: 2px;
  /* position: absolute;
  top: 30px;
  left: 165px;
  width: 20px; */
}
.receive-save-wrapper p {
  margin-left: -20px;

}
.error-msg{
  color: red !important;
  margin-top: 20px;
}
.invalid {
  border-color: #f00 !important;
}

.valid {
  border-color: #7c7e7f !important;
}

/*--- Responsive ---*/
@media (max-width: 1600px) {
  .container{
    width: 100%;
  }
  .field-element.amount {
    width: 10%;
  }
  }

@media (max-width: 1040px) {
  .upload-button{
    padding: 9px 48px;
  }
  .image-item__btn-wrapper{
    display: none;
  }
  .button-message{
    max-width: 32%;
  }
  .alert.alert-success.mt-3.mb-0 {
    left: 4px;
}
  .mobile-view {
    display: block;
    /* margin: 50px 0px 30px 0px; */
  }
  .image-item div img{
    object-fit: contain;
    display:inline-block;
    width: 100%;
    height: 35px;
    padding: 5px;
  }
  .image-item{
    position: absolute;
    top: 0px;
    left: 123px;
  }
  .image-item div{
    width: 35px;
    position: relative;
  }
  .upload-images-div{
    margin: unset;
  }
  .desk-view {
    display: none;
  }
  .alert.alert-success.mt-3.mb-0{
    text-align: left;
  }
}
@media (max-width: 780px) {
  .alert.alert-success.mt-3.mb-0 {
    left: 67px;
}
.button-message {
  width: 100%;
   max-width: 100%; 
  /* margin: auto; */
}
.mileage .dashboard-icon-upload{
display: none;
}
}
@media (max-width: 720px) {
  .add-icon{
    margin-left: 0;
  }
  .mileage .error-section {
    position: absolute;
    top: 75%;
    left: 0;
}
  .button-wrap{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .cc-class .form-custom-class-prefix__value-container, 
  .cc-class .form-custom-class-prefix__indicators {
    position: relative;
    top: 3px;
}
  .alert.alert-success.mt-3.mb-0 {
    left: 0px;
    top: 0;
}
.button-message{
  align-items: baseline;
}
  .upload-images-div{
    width: 100%;
  }
  .receipts-form {
    padding-left: 10px;
    padding-right: 10px;
}
.mileage h6.upload-receipt-title {
  margin:0 auto;
}
  .mobile-view {
    display: block;
    /* margin: 24px 0px 30px 0px; */
  }
  .image-item div img{
    object-fit: contain;
    display:inline-block;
    width: 100%;
    height: 35px;
    padding: 5px;
  }
  .image-item{
    position: absolute;
    top: 0px;
    left: 123px;
  }
  .image-item div{
    width: 35px;
    position: relative;
  }
  .mileage .upload-images-div{
    margin: unset;
  }
  .desk-view {
    display: none;
  }
  .upload-button, .mileage .container .btn-primary{
    padding: 3px 36px 4px 45px;
    width: 120px;
  }
  
  .receipt-save {
    padding: 7px 34px!important;
}
input.receipt-check {

  width: 15px;
}

  form .field-group {
    display: block;
    margin-bottom: 0;
  }

  .field-element {
    width: 100%;
    margin-top: 20px;
    position: relative;
  }
  .second-section .field-element{
    width: 100%;
  }
  .field-group .field .form-custom-class-prefix__control{
    min-height: 27px;
  }
  
  input[type="date"] {
    width: 100%;
    border: none;
    color: #000;
    text-align: left;
    -webkit-appearance: none;
    border-bottom: 1px solid #7c7e7f;
    outline: none;
    background: transparent;
    padding-top: 3px;
  /* padding-right: 78%; */
    position: relative;
    display: inline-block;
    border-radius: 0;
  }
  .mileage input[type="text"]{
    width: 100%;
    border: none;
    border-bottom: 1px solid #7c7e7f;
    height: 20px!important; 
    outline: none;
    background: transparent;
    padding-top: 0px!important; 
}
.second-section label{
  margin-bottom: .5rem;
}
/* .receipts-form .sign-btn {
  gap: 59px;
} */

/* form .field-group .label{
  padding-top: 30px!important;
} */
}
/* form .field-group .label{
  padding-top: 25px!important;
}
.field-group .field .form-custom-class-prefix__control{
  padding-top: 0px!important;
  height: 0px!important;
}
.css-1s2u09g-control{
  min-height: 24px!important;
}
.css-6j8wv5-Input {
  margin: 0px!important;
  padding-bottom: 0px!important;}
} */
@media (max-width: 460px) {
  /* .upload-images-div {
    width: 70%;
} */
.receipts-form .sign-btn {
  gap: 29px;
}
.toast-msg .alert-success {
  padding: 5px 19px 8px;
  font-size: 14px;
}
.toast-msg {
  right: 20px;
  top: 56px;
}
/* input.receipt-check{
  left: 134px;
} */
}