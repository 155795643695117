.main {
    flex: 1;
}

.main>div {
    padding: 0 !important;
}

.login nav {
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #eaeaea;
}

main {
    height: calc(100vh - 180px);
    min-height: inherit;
    padding: 0;
}

main .container {
    height: 100%;
}

.row {
    display: flex;
}

main .container .row {
    height: 100%;
    align-items: center;
    justify-content: center;
}

img {
    max-width: 100%;
}

.form {
    width: 80%;
    margin: 0 auto;
}

.login-form .form-group {
    margin-bottom: 60px;
}

.form-control-wrapper {
    width: 100%;
}

.form-control-label {
    position: relative;
}

.label-class {
    color: #000000 !important;
}

.login-form .form-group label {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.container .btn-primary {
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    background-color: #2489fb;
    border-color: #2489fb;
    font-size: 12px;
    border-radius: 0;
    color: #fff;
    padding: 12px 35px 12px 35px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn {
    border-radius: 0;
    padding: 10px 55px;
    text-transform: uppercase;
}

.btn:hover {
    text-decoration: none;
}

.login-form .sign-btn {
    text-align: center;
}

input[type="text"],
input[type="password"], .receipts input#input-example {
    width: 100%;
    border: none;
    border-bottom: 1px solid #7c7e7f;
    height: 38px;
    outline: none;
    background: transparent;
    padding-top: 10px;
}

.form input:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-color: #7c7e7f;
}

.form input {
    border: none;
    border-bottom: solid 1px #7c7e7f;
    background-color: transparent;
    color: #000000;
    padding: 0;
    transition: all 0.35s ease-in-out;
    border-radius: 0px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    box-shadow: none;
}

.error-message {
    color: #f00;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    position: absolute;
}

.invalid {
    border-color: #f00 !important;
}

.valid {
    border-color: #7c7e7f !important;
}



@media(max-width: 720px) {

    form {
        border: none;
        box-shadow: none;
        padding: 20px 0;
    }

    .login-form {
        max-width: 100%;
        flex: auto;
    }

}