
.modal-open{
  .filter-modal{
    .modal-lg, .modal-xl {
      --bs-modal-width: 75%;
    }
    label{
      margin-bottom: 0;
    }
  .modal-backdrop{
    --bs-backdrop-opacity: 0.72;
  }
  .modal-content {
   
    background-color: transparent;
    
    border: 0;
    border-radius: 0;
    outline: 0;
    .btn{
      --bs-btn-border-radius: 0;
      width: 80px;
      height: 80px;
      border: 0;
    }
    button.close-button.btn.btn-primary {
      background: red;
      &:hover{
        background: rgb(241, 10, 10);
      }
  }
  button.close-button.btn-primary:not(:disabled):not(.disabled):active{
    background: red;
    
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus{
    box-shadow: none;
  }
  button.btn.reset-button {
    background: #fff;
    border: 2px solid #ccc!important;
}
  }
  .form-overlay {
    // background: #fff;
    // padding: 1em 1em;
  }
  .modal-body {
    // display: flex;
    // align-items: flex-start;
    padding: 0;
  }
  h4 {
    font-size: 32px;
    color: #fff;
  }
  .flex{
    flex-direction: column;
  }
  .css-1n7v3ny-option, .css-yt9ioa-option{
    display: flex;
    align-items: baseline;
  }
}
}
.filter-dashboard, .export-dashboard{
  background: #fff;
  padding: 1em 2em;
width: 100%;
}
.filter-dashboard{
min-height: calc(100vh - 470px);
}
.export-dashboard{
  display: flex;
  align-items: center;
  p{
    font-size: 19px;
    margin: 1em 0;
    font-weight: 600;

  }
}

.edit-receipts{
    form {
      padding: 15px 0;
      display: flex;
    }
    .d-range {
    display: flex;
      input[type="date"] {
      width: 48%;
      margin-top: 9px;
    }
      .fm-date{
        margin-right: 4px;
      }
      .to-date{
        margin-left: 4px;
      }
  }

    main{
      height: auto;
    }
    
    form .field-group {
      display: flex;
      margin-bottom: 30px;
      justify-content: space-between;
    }
   
    form .field-group .label {
      flex: 1.5;
      text-transform: uppercase;
      font-size: 12px;
      color: #000;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      padding-top: 14px;
    position: relative;
    }
    .mandatory-field {
      color: red!important;
      position: absolute;
      font-size: 12px;
      margin-left: 3px;
    }
    form .field-group .field {
      flex: 3;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }
    .text-center{
      text-align: center;
    }
    .field-element {
      width: 32%;
    }
    .second-section .field-element{
      width: 23.5%;
      /* height: 70px; */
    }
    /*.form-column-layout{
      display: flex;
      flex-basis: 100%;
      flex: 1;
    }*/
    .receipts input{
      border: none;
      border-bottom: 1px solid #7c7e7f;
      outline: none;
      background: transparent;
    
    }
    input {
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      padding: 10px 2px 2px 3px;
      border-radius: 0;
    }
    
    .second-section label, .third-section label{
      margin-bottom: 0;
    }
    input[type="date"] {
      width: 100%;
      border: none;
      color: #000;
      text-align: left;
      -webkit-appearance: none;
      border-bottom: 1px solid #7c7e7f;
      outline: none;
      background: transparent;
      padding-top: 8px;
     padding-right: 0;
      position: relative;
      display: inline-block;
      border-radius: 0;
    }
    .upload-button:hover {
      color: #fff;
      background-color: green;
      border-color: green;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
    
    .field .css-1wa3eu0-placeholder {
      top: 70%;
      color: #000;
      margin: 0;
    }
    
    .field .css-1okebmr-indicatorSeparator {
      display: none;
    }
    
    .field-group .field .form-custom-class-prefix__control {
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid #7c7e7f;
      cursor: pointer;
      border-radius: 0;
      height: 30px;
    }
    .cc-class .form-custom-class-prefix__value-container , .cc-class .form-custom-class-prefix__indicators{
      position: relative;
        top: 7px;
    }
    .field-group .field .form-custom-class-prefix__menu {
      cursor: pointer !important;
    }
    
    .field-group .field .form-custom-class-prefix__menu__option--is-focused {
      cursor: pointer !important;
    }
    
    .field-group .field .form-custom-class-prefix__input-container {
      padding-top: 0px;
    }
    
    .form-custom-class-prefix__input-container {
      padding-bottom: 0;
    }
    
    .field-group .field .form-custom-class-prefix__indicator {
      color: #000;
      padding: 0;
    }
    
    .form-custom-class-prefix__indicator .form-custom-class-prefix__clear-indicator {
      padding: 0;
    }
    
    .field-group .field .form-custom-class-prefix__placeholder {
      color: #000;
    }
    
    .field-group .field .form-custom-class-prefix__value-container {
      padding: 3px 1px;
    }
    
    .field-group .field .form-custom-class-prefix__indicator svg {
      height: 15px;
    }
    
    input[type="file"] {
      border: none;
      display: block!important;
    }
    
    input[type="file"] {
      position: absolute;
      z-index: -1;
      top: 10px;
      left: 14px;
      color: #fff;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }
    
    .upload__image-wrapper {
      display: inline-flex;
      /*justify-content: center;*/
      align-items: center;
    }
    .button-message {
      /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; */
      width: 100%;
      max-width: 20%;
      margin: auto;
    }
    .button-wrap {
     position: relative;
    
    }
    .alert.alert-success.mt-3.mb-0{
      background: transparent;
      /* color: #4ebd4e; */
      text-align: center;
      border: 0;
      padding: 0;
      position: relative;
      top: -30px;
      left: 57px;
    }
    .alert.alert-success.green.mt-3.mb-0{
      color: #4ebd4e; 
    }
    .flex{
      display: flex;
    }
    .flex .credit{
      text-align: right;
      margin-right: 5px;
    }
    .flex label{
      position: relative;
      left: 5px;
      cursor: pointer;
    }
    
    .flex input{
      cursor: pointer;
    }
    .upload-images-div {
      margin-left: 54px;
      /* display: flex;
      justify-content: center;
      flex-direction: column; */
      /* align-items: stretch; */
      /* width: 11%;
      margin: 0 auto; */
    }
    .upload-button {
      width: 146px;
      display: inline-block;
      padding: 14px 17px;
      cursor: pointer;
      background-color: #49aa49;
      font-size: 12px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #fff;
      border: none;
      text-transform: uppercase;
      text-align: center;
    }
    .receipts .container .btn-primary{
      width: 146px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image-item__btn-wrapper button {
      background-color: transparent;
      border: none;
      position: absolute;
      bottom: 26px;
      left: 26px;
    
    }
    
    .image-item__btn-wrapper button:hover {
      background-color: transparent;
      color: red;
      border: none;
      position: absolute;
      bottom: 26px;
      left: 26px;
    }
    
    .image-item {
      margin-left: 10px;
      display: flex;
      flex-wrap: wrap;
      height: max-content;
    
      border: 1px solid #ccc;
    
    }
    
    .image-item div {
      width: 45px;
      position: relative;
    }
    
    .image-item div img {
      object-fit: contain;
      display: inline-block;
      width: 100%;
      height: 45px;
      padding: 9px;
    
    }
    
    .image-item:hover {
      border: 1px solid red;
    }
    
    .logireceiptsn-form .receipt-check {
      position: absolute;
      bottom: 76px;
      margin-left: 50px;
    }
    
    .desk-view .label {
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      text-transform: uppercase;
    }
    
    .mobile-view {
      display: none;
    }
    
    .desk-view {
      display: block;
    }
    
    /*.receive-save-wrapper {
      position: absolute;
      bottom: 38%;
      left: 46.6%;
    }*/
    .file-format{
      font-size: 11px;
        position: relative;
        top: -25px;
    }
    .receipts-form .sign-btn {
      display: flex;
      align-items: baseline;
      gap: 26px;
      position: relative;
      right: 60px;
      flex-direction: row;
    }
    
    input.receipt-check {
      position: relative;
        top: -5px;
      /* position: absolute;
      top: 30px;
      left: 165px;
      width: 20px; */
    }
    .receive-save-wrapper p {
      margin-left: -20px;
    
    }
    .error-msg{
      color: red !important;
      margin-top: 20px;
    }
    .modal-header .btn-close{
      margin: 0;
    }
    }
    /*--- Responsive ---*/
    @media (max-width: 1040px) {
      .upload-button{
        padding: 9px 48px;
      }
      .image-item__btn-wrapper{
        display: none;
      }
      .button-message{
        max-width: 32%;
      }
      .alert.alert-success.mt-3.mb-0 {
        left: 4px;
    }
      .mobile-view {
        display: block;
        /* margin: 50px 0px 30px 0px; */
      }
      .image-item div img{
        object-fit: contain;
        display:inline-block;
        width: 100%;
        height: 35px;
        padding: 5px;
      }
      .image-item{
        position: absolute;
        top: 0px;
        left: 123px;
      }
      .image-item div{
        width: 35px;
        position: relative;
      }
      .upload-images-div{
        margin: unset;
      }
      .desk-view {
        display: none;
      }
      .alert.alert-success.mt-3.mb-0{
        text-align: left;
      }
    }
    @media (max-width: 780px) {
      .alert.alert-success.mt-3.mb-0 {
        left: 67px;
    }
    .button-message {
      width: 100%;
       max-width: 100%; 
      /* margin: auto; */
    }
    }
    @media (max-width: 720px) {
      .button-wrap{
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .cc-class .form-custom-class-prefix__value-container, 
      .cc-class .form-custom-class-prefix__indicators {
        position: relative;
        top: 3px;
    }
      .alert.alert-success.mt-3.mb-0 {
        left: 0px;
        top: 0;
    }
    .button-message{
      align-items: baseline;
    }
      .upload-images-div{
        width: 100%;
      }
      .receipts-form {
        padding-left: 10px;
        padding-right: 10px;
    }
      .mobile-view {
        display: block;
        /* margin: 24px 0px 30px 0px; */
      }
      .image-item div img{
        object-fit: contain;
        display:inline-block;
        width: 100%;
        height: 35px;
        padding: 5px;
      }
      .image-item{
        position: absolute;
        top: 0px;
        left: 123px;
      }
      .image-item div{
        width: 35px;
        position: relative;
      }
      .upload-images-div{
        margin: unset;
      }
      .desk-view {
        display: none;
      }
      .upload-button, .receipts .container .btn-primary{
        padding: 3px 36px 4px 45px;
        width: 120px;
      }
      
      .receipt-save {
        padding: 7px 34px!important;
    }
    input.receipt-check {
    
      width: 15px;
    }
    
      form .field-group {
        display: block;
        margin-bottom: 0;
      }
    
      .field-element {
        width: 100%;
        margin-top: 20px
      }
      .second-section .field-element{
        width: 100%;
      }
      .field-group .field .form-custom-class-prefix__control{
        min-height: 27px;
      }
      
      input[type="date"] {
        width: 100%;
        border: none;
        color: #000;
        text-align: left;
        -webkit-appearance: none;
        border-bottom: 1px solid #7c7e7f;
        outline: none;
        background: transparent;
        padding-top: 3px;
      /* padding-right: 78%; */
        position: relative;
        display: inline-block;
        border-radius: 0;
      }
      .receipts input[type="text"]{
        width: 100%;
        border: none;
        border-bottom: 1px solid #7c7e7f;
        height: 20px!important; 
        outline: none;
        background: transparent;
        padding-top: 0px!important; 
    }
    .second-section label{
      margin-bottom: .5rem;
    }
    /* .receipts-form .sign-btn {
      gap: 59px;
    } */
    
    /* form .field-group .label{
      padding-top: 30px!important;
    } */
    }
    /* form .field-group .label{
      padding-top: 25px!important;
    }
    .field-group .field .form-custom-class-prefix__control{
      padding-top: 0px!important;
      height: 0px!important;
    }
    .css-1s2u09g-control{
      min-height: 24px!important;
    }
    .css-6j8wv5-Input {
      margin: 0px!important;
      padding-bottom: 0px!important;}
    } */
    @media (max-width: 460px) {
      /* .upload-images-div {
        width: 70%;
    } */
    .receipts-form .sign-btn {
      gap: 29px;
    }
    
    /* input.receipt-check{
      left: 134px;
    } */
    }
    .field.reconciled {
      margin-top: 34px;
  }
  .field.reconciled label{
    cursor: pointer;
}
  .field.reconciled input[type="checkbox"] {
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.field.reconciled input{
  position: relative;
  top: -1.4px;
}
