@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v21/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  background-color: #f8fdff;
  font-size: 18px;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  // border-bottom: 1px solid #eaeaea;
  padding:15px 0 5px 0;
}
.nav-cls{
  border-bottom: 1px solid #eaeaea;
}
nav .basic{
  flex-basis: 33.3%;
}
img.home-icon {
  width: 34px;
  margin-left: 15px;
  cursor: pointer;

}

nav img {
  
  max-width: 100%;
}
// .spinner-border{
//   left: 47%;
//     top: 50%;
//     position: absolute;
// }
.logo {
  width: 140px;
  text-align: center;
}
.field-element-type{
  width: 12% !important;
}
.flex-row{
  display: flex;
  justify-content: space-between;
}
/* .flex-row.logout a, button {
  color: #000 !important;
} */
.dashboards{
  @media (max-width: 1600px) {
    padding-top: 0px!important;
    max-height: calc(100vh - 120px);
    min-height: calc(100vh - 120px);
  }
}
.dashboard-icon{
  text-align: right;
  margin-right: 11px;
  position: relative;
    top: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span{
      position: relative;
    top: 2px;
    right: 3px;
    }
}
.mobile-user{
  display: none;
}
.desk-top-user{
  display: block;
}
.dashboard-icon img{
  cursor: pointer;
  margin-left: 20px;
}

.cc-dropdown .form-custom-class-prefix__option {
  padding: 0;
}
.gray-bg{
  background-color: #e0e0e0;
  color: red;
  &:hover{
    background-color: #9bb8df;
  }
}
.form-custom-class-prefix__value-container--has-value{
  .gray-bg{
    background-color: transparent;
  }
  .common-bg{
    padding: 0;
  }
} 
.form-custom-class-prefix__option--is-selected .gray-bg{
  background-color: #2684FF !important;
  color: #fff;
}

.common-bg{
  padding: 8px 12px;
}
.css-1rhbuit-multiValue {
  width: 56px;
  .common-bg{
    padding: 0;
  }
}
  //responsive
  @media (max-width: 780px) {
  .desk-top-user{
    display: none;
  }
  .mobile-user{
    display: block;
    display: flex;
    justify-content: flex-end;
    align-items: inherit;
    text-align: right;
    font-size: 14px;
    padding-right: 17px;
  }
}
.form-custom-class.current-user {
  pointer-events: none;
  cursor: default;
  opacity: 0.8;
}
.no-records{
  display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.css-12jo7m5{padding-left: 3px!important;}
.tooltip {
  pointer-events: none;
}
span.badge.badge-secondary {
  border-radius: 0;
}
span.badge {
  width: 89px;
  padding: 8px 5px 7px 5px;
}
span.badge.badge-orange {
  background-color: orange;
}
span.badge.badge-purple {
  background-color: #ac05ac;
}
.filter-count{
  background: #0d6efd;
  color: #fff;
  width: 18px;
  height: 18px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  position: relative;
  top: -15px;
  border-radius: 10px;
  left: -10px;
}