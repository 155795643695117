.missing {

  padding: 20px 0 0;

  height: calc(100vh - 132px);

  .dash-icon {

    margin-top: 0;

    margin-bottom: 20px;

  }

  .dash-trigger-icons {

    margin-right: 13px;

  }

  h6.dash-title-dashboard {

    margin-left: 119px !important;

  }



  img {

    height: 30px;

    margin-left: 10px;

  }





  .spinner-border {

    left: 47%;

    top: 50%;

    position: absolute;

    color: #007bff;

  }

  .filter-container {

    text-align: right;

    a {

      cursor: pointer;

      padding: 10px 0;

    }

  }

  .dashboard main .container {

    height: 100%;

    max-width: 95%;

    display: flex;

  }



  .table-container {

    width: 100%;

  }



  .credit-table-container {

    width: 100%;

    table-layout: fixed;

  }



  .credit-list-table {

    width: 100%;

    table-layout: fixed;

  }



  .credit-table-container .table-container .scroll-bar {

    // max-height: calc(100vh - 390px);

    // min-height: calc(100vh - 420px);

  }



  .scroll-bar {

    overflow: overlay;

    max-height: calc(100vh - 366px);

    // min-height: calc(100vh - 335px);
    min-height: calc(100vh - 345px);

    position: relative;

    @media (max-width: 1600px) {



      max-height: calc(100vh - 347px);

      min-height: calc(100vh - 352px);



    }

  }



  td img,
  td span {

    display: inline-block;

  }

  ::-webkit-scrollbar {

    width: 8px;

  }



  /* Track */

  ::-webkit-scrollbar-track {

    background: transparent;

  }



  /* Handle */

  ::-webkit-scrollbar-thumb {

    background: #9c9c9c;

    min-height: 35px;

  }



  table {

    caption-side: bottom;

    border-collapse: collapse;

  }



  thead tr {

    cursor: default;

    height: 20px;



  }







  thead tr th {

    word-wrap: break-word;

    cursor: pointer;

  }



  table.table-layout thead tr {

    border-bottom: 1px solid #9d9da2;

    vertical-align: top;

  }



  table.table-layout thead th {

    color: #4068fb;

    text-transform: uppercase;

    font-family: 'Montserrat', sans-serif;

    font-size: 13px;

    font-weight: 600;

  }



  tbody tr {

    border-top: 1px solid #d3d7e0;

    height: 20px;



  }



  tbody tr td {

    position: relative;

    padding: 5px 1px;

    word-wrap: break-word;

  }



  table.table-layout tbody tr {

    color: #000;

    text-transform: capitalize;

    font-family: 'Montserrat', sans-serif;

    font-size: 12px;

    font-weight: 500;

    letter-spacing: 1px;

  }



  tr {

    td.negative {

      color: red;

    }

    /*td.number{

  text-align: right;

}*/

    td,
    th {

      &:nth-child(1) {

        width: 20%;

      }

      &:nth-child(2) {

        width: 13%;

      }

      &:nth-child(3) {

        width: 8%;

        text-align: right;



      }





      &:nth-child(4) {

        width: 22%;

        padding-left: 76px;

      }



    }



    td:nth-child(4) {

      padding-left: 80px;

    }

    td:nth-child(3) {



      padding-right: 13px;

    }

    @media (max-width: 1600px) {

      th:nth-child(3) {

        width: 10%;

        text-align: right;



      }

      td:nth-child(3) {

        width: 10%;

        padding-right: 11px;

      }

    }



  }

  .credit-list-table .uploadImages {

    display: flex;

  }

  .container {

    max-width: 96%;

  }

  .credit-list-table .uploadImages img {

    height: 34px;

    margin-left: 3px;



  }



  /*table.table-layout thead th:nth-child(11),

table.table-layout tbody td:nth-child(11) {

  width: 3%;

}*/

  main {

    height: auto;

  }

  .sort-default-icon {

    background: url(../../../public/assets/icons/sort-default.svg) no-repeat;

    padding-left: 11px;

    position: relative;

    top: -2px;

    background-position: 0px 4px;

  }

  .sort-asc-icon {

    background: url(../../../public/assets/icons/sort-asc.svg) no-repeat;

    padding-left: 11px;

    position: relative;

    top: -2px;

    background-position: 0px 4px;

  }

  .sort-desc-icon {

    background: url(../../../public/assets/icons/sort-desc.svg) no-repeat;

    padding-left: 11px;

    position: relative;

    top: -2px;

    background-position: 0px 4px;

  }

}

.tooltip {

  pointer-events: none;

}

span.badge.badge-secondary {

  border-radius: 0;

}

span.badge {

  width: 89px;

  padding: 8px 5px 7px 5px;

}

span.badge.badge-orange {

  background-color: orange;

}

span.badge.badge-purple {

  background-color: #ac05ac;

}

span.count-records {

  color: #000;

  font-size: 18px;

  margin-left: 20px;

}

.popover {

  position: fixed !important;

}





.form-check-input {

  width: 1.4em;

  height: 1.4em;

  margin-top: 3px;

}

.export-modal {

  .receipts input {

    border: 1px solid #262323;

    border-bottom: 1px solid #262323;

    outline: none;



    cursor: pointer;

  }

  .form-check {



    margin-bottom: 0.52rem;

    label,
    input {

      cursor: pointer;

    }

    label {



      font-size: 20px;

    }

    .form-check-input {

      width: 1.4em;

      height: 1.4em;

      margin-left: -24px;

      margin-top: 6px;

    }

  }

  .filter-dashboard {

    display: flex;

    align-items: center;

  }

  &.filter-modal .modal-lg,
  .modal-open .filter-modal .modal-xl {

    --bs-modal-width: 20% !important;

  }

  &.delete-modal .modal-lg,
  .modal-open .delete-modal .modal-xl {

    --bs-modal-width: 27% !important;

    form {

      display: flex;

      flex-direction: column;

    }

    .flex {

      flex-direction: row;

      .btn {

        width: 100%;

        height: 55px;

      }

    }

  }

  .receipts .spinner-border {

    left: 37%;

    top: 42%;



  }

}

#tooltip-bottom,
#tooltip-export,
#tooltip-upload,
#tooltip-filter {

  position: fixed;

}

.missing-receipts.table-layout tr:hover {

  cursor: pointer;

  background: #eaeff1;

}



.userlist-missing-receipts ul {

  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;

  gap: 15px;
  // max-height: calc(100vh - 795px);
  // overflow: overlay;

}

.userlist-missing-receipts ul li {

  flex: 0 0 22%;

}

@media (max-width: 1600px) {

  .userlist-missing-receipts ul li {

    flex: 0 0 23%;

  }

}

.userlist-missing-receipts ul li .userlist-details .user-name {



  text-transform: uppercase;

}

.userlist-missing-receipts ul li .userlist-details {

  display: flex;

  justify-content: space-between;

}

.userlist-missing-receipts ul li .userlist-details .total-count {

  font-weight: 700;



}
// @media (max-width: 3072px  min-width: 2400px) {
//   .userlist-missing-receipts {
//     height: calc(100vh - 1300px);
//     margin: 0 auto;
//     overflow: overlay;
//     padding-bottom: 20px;
//     width: 90%;
//     margin-bottom: 20px;
// }
// }



   
div.userlist-missing-receipts {
  // height: calc(100vh - 960px);
  height: 100px;
  margin: 0 auto;
  overflow: overlay;
  padding-bottom: 20px;
  width: 90%;
  // margin-bottom: 20px;
}

.userlist-missing-receipts {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 30px;
  // height: calc(100vh - 680px);
  overflow: overlay;
  @media (max-width: 1600px) {
    height: calc(100vh - 594px);
  }

}


// @media (max-width: 2048px) {
//   div.userlist-missing-receipts {
//       height: calc(100vh - 840px);
//   }
//   }
//   @media (min-width: 2880px) {
//   div.userlist-missing-receipts {
//     height: 250px;
//   }
//   .missing .scroll-bar{
//   max-height: calc(100vh - 600px);
//   min-height: 1200px;
//   // min-height: calc(100vh - 580px);
//   }
//   }
  
 
  @media (max-width: 1600px) {
    div.userlist-missing-receipts {
        height: calc(100vh - 628px);
    }
    }
  // @media (max-width: 1800px) {
  // div.userlist-missing-receipts {
  //    height: calc(100vh - 681px);
  // }
  // }
 
  @media (max-width: 1920px) {
    div.userlist-missing-receipts {
        
         height: 100px;
    }
    .missing .scroll-bar{
    max-height: calc(100vh - 530px);
    // min-height: calc(100vh - 402px);
    min-height: calc(100vh - 350px);
    }
    }

    @media (min-width: 2130px) {
      div.userlist-missing-receipts {
          
           height: 140px;
      }
      .missing .scroll-bar{
      max-height: calc(100vh - 500px);
      min-height: calc(100vh - 410px);
      }
      }

  
