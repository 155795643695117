.dashboard {
  padding: 20px 0 0;
  height: calc(100vh - 132px);
  .dash-icon{
    margin-top: 0;
      margin-bottom: 20px;
  }
  .dash-trigger-icons{
    margin-right: 13px;
  }
  h6.dash-title-dashboard{
    margin-left: 119px !important;
  }
img {
  height: 30px;
  margin-left: 10px;
}

  
  .spinner-border{
  left: 47%;
    top: 50%;
    position: absolute;
    color: #007bff;
}
  .filter-container{
    text-align: right;
    a{
      cursor: pointer;
      padding: 10px 0;
    }
  }
.dashboard main .container {
  height: 100%;
  max-width: 95%;
  display: flex;
}

.table-container {
  width: 100%;
}

.credit-table-container {
  width: 100%;
  table-layout: fixed;
}

.credit-list-table {
  width: 100%;
  table-layout: fixed;
}

.credit-table-container .table-container .scroll-bar {
  // max-height: calc(100vh - 390px);
  // min-height: calc(100vh - 420px);
}

.scroll-bar {
  overflow: overlay;
  max-height: calc(100vh - 235px);
  min-height: calc(100vh - 235px);
  position: relative;
  @media (max-width: 1600px) {
    max-height: calc(100vh - 227px);
    min-height: calc(100vh - 227px);
  }
}
thead{
  white-space: nowrap;
}
thead tr:after {
  content: "";
  width: 0.6%;
  display: table-cell;
}

td img, td span{
  display: inline-block;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  min-height: 35px;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

thead tr {
  cursor: default;
  height: 20px;

}

thead tr th {
  word-wrap: break-word;
  cursor: pointer;
}

table.table-layout thead tr {
  border-bottom: 1px solid #9d9da2;
  vertical-align: top;
}

table.table-layout thead th {
  color: #4068fb;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 600;
}

tbody tr {
  border-top: 1px solid #d3d7e0;
  height: 20px;

}

tbody tr td {
  position: relative;
  padding: 5px 1px;
  word-wrap: break-word;
}

table.table-layout tbody tr {
  color: #000;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

tr {
  td.negative {
    color: red;
}/*td.number{
  text-align: right;
}*/
  td, th{
    &:nth-child(1){
          width: 11%;
    }
    &:nth-child(2){
      width: 9%;
}
&:nth-child(3){
  width: 8%;
}
    &:nth-child(4){
      width:7%;
}
&:nth-child(9){
  width: 9%;
  padding-left: 18px;
}
&:nth-child(5){
  width: 8%;
  text-align: right;
  padding-right: 18px;
}
&:nth-child(6){
  width: 10%;
    padding-left: 30px;
}
&:nth-child(8){
  width: 10%;
  padding-left: 18px;
  white-space: nowrap;   
    overflow: hidden;
    text-overflow: ellipsis;  
}
&:nth-child(7){
  width: 8%;
  text-align: right;
  padding-right: 18px;
}
@-moz-document url-prefix() {
  &:nth-child(7){
    width: 8%;
    text-align: left;
    padding-right: 18px;
  }
  
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  &:nth-child(7){
    width: 8%;
    text-align: left;
    padding-right: 18px;
  }
}}
&:nth-child(10){
  width: 8%;
    
}
&:nth-child(12){
  width: 5%;
  @media (max-width: 1600px) {
    width: 7%;
  }
  img{
    cursor: pointer;
  }
}
&:nth-child(11){
  width: 3%;
  text-align: center;
  @media (max-width: 1600px) {
    width: 4%;
  }
  img{
    cursor: pointer;
  }
}
  }
  td:nth-child(7) {
    padding-right: 28px;
}
td:nth-child(5) {
  padding-right: 29px;
}
}
.credit-list-table .uploadImages {
  display: flex;
}
.container{
  max-width: 96%;
}
.credit-list-table .uploadImages img {
  height: 34px;
  margin-left: 3px;

}

/*table.table-layout thead th:nth-child(11),
table.table-layout tbody td:nth-child(11) {
  width: 3%;
}*/
main{
  height: auto;
}
.sort-default-icon{
background: url(../../../public/assets/icons/sort-default.svg) no-repeat;
padding-left: 11px;
position: relative;
top: -2px;
background-position: 0px 4px;
}
.sort-asc-icon{
  background: url(../../../public/assets/icons/sort-asc.svg) no-repeat;
  padding-left: 11px;
  position: relative;
  top: -2px;
  background-position: 0px 4px;
  }
  .sort-desc-icon{
    background: url(../../../public/assets/icons/sort-desc.svg) no-repeat;
    padding-left: 11px;
    position: relative;
    top: -2px;
    background-position: 0px 4px;
    }
}
.tooltip {
  pointer-events: none;
}
span.badge.badge-secondary {
  border-radius: 0;
}
span.badge {
  width: 89px;
  padding: 8px 5px 7px 5px;
}
span.badge.badge-orange {
  background-color: orange;
}
span.badge.badge-purple {
  background-color: #ac05ac;
}
span.badge.badge-blue {
  background-color: #0f18d9;
}
span.count-records {
  color: #000;
  font-size: 18px;
  margin-left: 20px;
}
.popover{
  position: fixed !important;
}


.form-check-input {
  width: 1.4em;
  height: 1.4em;
  margin-top: 3px;
}
.export-modal{
  .receipts input{
    border: 1px solid #262323;
      border-bottom: 1px solid #262323;
      outline: none;
    
      cursor: pointer;
  }
  .form-check{

    margin-bottom: 0.52rem;
    label, input {
      cursor: pointer;
    }
    label {
    
      font-size: 20px;
  }
  .form-check-input {
    width: 1.4em;
    height: 1.4em;
    margin-left: -24px;
    margin-top: 6px;
}
  }
  .filter-dashboard{
  display: flex;
    align-items: center;
  }
  &.filter-modal .modal-lg, .modal-open .filter-modal .modal-xl {
    --bs-modal-width: 20% !important;
  }
  &.delete-modal .modal-lg, .modal-open .delete-modal .modal-xl {
    --bs-modal-width: 27% !important;
    form{
      display: flex;
      flex-direction: column;
    }
    .flex{
      flex-direction: row;
      .btn{
        width: 100%;
        height: 55px;
      }
    }
  }
  .receipts .spinner-border {
    left: 37%;
    top: 42%;
   
}
}
#tooltip-bottom, #tooltip-export, #tooltip-upload, #tooltip-filter{
  position: fixed;
}