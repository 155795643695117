.dashboard_upload .btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
    
}

.dashboard_upload .btns a{
    width: 395px;
}
@media (max-width: 768px) {
.dashboard_upload .btns {
    max-width: 280px;
    margin: 0 auto;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 80px;
    margin-bottom: 20px;
}
.dashboard_upload .dashboard-btn{
    display: none;
}
}